import React from "react"
import _ from "lodash"
import ClientTestimonial from "../components/ClientTestimonial/ClientTestimonial"

const renderClientTestimonial = (node, locale, defaultLocale) => {
  const props = {
    author: _.get(node, "data.target.author", ""),
    detail: _.get(node, "data.target.detail", ""),
    testimonial: _.get(node, "data.target.testimonial.testimonial", null)
  }

  return (
    <ClientTestimonial
      text={props.testimonial}
      author={props.author}
      info={props.detail}
    />
  )
}

export const renderContentNode = (node, locale, defaultLocale) => {
  const entryId = _.get(node, "data.target.__typename")

  switch (entryId) {
    case "ContentfulTestimonial":
      return renderClientTestimonial(node, locale, defaultLocale)
    default:
      break
  }
}
