import React from "react"
import PropTypes from "prop-types"
import styles from "./ClientTestimonial.module.scss"

const ClientTestimonial = props => {
  function renderInfo() {
    if (props.author || props.info) {
      const text = [props.author, props.info].filter(item => item).join(", ")
      return <div className={styles.info}>{text}</div>
    }

    return null
  }
  return (
    <div className={styles.testimonial}>
      <div className={styles.text}>{props.text}</div>
      {renderInfo()}
    </div>
  )
}

ClientTestimonial.propTypes = {
  author: PropTypes.string,
  info: PropTypes.string,
  text: PropTypes.string
}

export default ClientTestimonial
